<template>
  <div class="alert-content" :class="props.type" v-bind="attrs">
    <el-icon class="icon"><Warning /></el-icon>
    <span>
      {{ props.text }}
    </span>
  </div>
</template>

<script setup>
import {useAttrs} from "vue";
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  type:{
    type:String,
    default:'error'
  }
})
const attrs = useAttrs()

</script>

<style lang="scss" scoped>
.alert-content{
  display: block;
  font-size: 12px;
  padding: 12px 14px;
  border-radius: 8px;
  &.error{
    background-color: #FFF2F2;
    color: #FF6669;
    >.icon,span{
      font-size:12px;
      color: #FF6669;
      vertical-align: middle;
      white-space: nowrap;
    }
    >.icon{
      margin-right: 4px;
    }
  }
}
</style>